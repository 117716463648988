/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { colors } from '@/theme'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { StaticImage } from 'gatsby-plugin-image'
import { ButtonLink } from './buttonLink'
import { ReverseRadius } from './reverseRadius'

const Root = styled(motion.div)`
  position: relative;
  min-height: 530px;
`
const BG = styled(motion.div)`
  background-color: ${colors.primary};
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
`
const ContentContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;
  flex-direction: column;
  z-index: 1;
  padding: 16px;
`
const ContentSizer = styled.div`
  max-width: 65em;
  text-align: right;
`

const ImageContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  padding: 10%;
  max-width: 600px;
  margin: 0;
  z-index: 1;
`
const Header = styled.h1`
  color: ${colors.textLight};
  text-align: right;
`
const Text = styled.p`
  text-align: right;
  color: ${colors.textLight};
`

const duration = 0.8
const delay = 0.2
const distance = `5%`

export const DownloadHeader: React.FunctionComponent<{
  defaultLink: string
  os: string
}> = ({ os, defaultLink }) => {
  return (
    <Root>
      <BG
        initial={{
          height: `0%`,
          borderRadius: `0 0 100% 100%`,
        }}
        transition={{
          duration: 1,
          ease: `easeOut`,
        }}
        animate={{
          height: `100%`,
          borderRadius: `0%`,
        }}
      />
      <div
        style={{ position: `absolute`, top: 0, right: 0, left: 0, bottom: 0 }}
      >
        <Grid container style={{ height: `100%` }}>
          <Grid item md={6}>
            <ContentContainer
              transition={{ duration, delay }}
              initial={{ opacity: 0, x: `-${distance}` }}
              animate={{ opacity: 1, x: `0%` }}
            >
              <ContentSizer>
                <Header>Download Tengable For&nbsp;Any&nbsp;Device.</Header>
                <Text>
                  <strong>Beta</strong>&nbsp;&nbsp;Please note, because the app
                  is new, you may receive a warning that the app is from an
                  unknown source. We assure you that it is secure to download.
                </Text>
                {os && defaultLink && (
                  <ButtonLink bgColor={colors.textLight} href={defaultLink}>
                    Download for {os}
                  </ButtonLink>
                )}

                {!os && (
                  <ButtonLink
                    bgColor={colors.textLight}
                    style={{ opacity: 0.5 }}
                  >
                    We do not have an app for your platform yet.
                  </ButtonLink>
                )}
              </ContentSizer>
            </ContentContainer>
          </Grid>
          <Hidden smDown>
            <Grid item md={6}>
              <ReverseRadius>
                <ImageContainer
                  transition={{ duration, delay }}
                  initial={{ opacity: 0, x: `${distance}` }}
                  animate={{ opacity: 1, x: `0%` }}
                >
                  <StaticImage
                    loading="eager"
                    placeholder="blurred"
                    alt="devices"
                    src="../img/undraw/undraw_real-time_sync_o57k.svg"
                    width={600}
                    height={499}
                  />
                </ImageContainer>
              </ReverseRadius>
            </Grid>
          </Hidden>
        </Grid>
      </div>
    </Root>
  )
}
