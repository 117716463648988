import React from 'react'
import { PageProps } from 'gatsby'
import platform from 'platform-detect'

import { PageLayout } from '@/components/pageLayout'
import { DownloadHeader } from '@/components/downloadHeader'
import Grid from '@material-ui/core/Grid'
// import GridList from '@material-ui/core/GridList'
// import GridListTile from '@material-ui/core/GridListTile'
// import Container from '@material-ui/core/Container'
import styled from 'styled-components'
import { ContentSlide } from '@/components/contentSlide/contentSlide'
import { StaticImage } from 'gatsby-plugin-image'
import { ButtonLink } from '@/components/buttonLink'
import { Container } from '@/components/container'

let os = ``
if (platform.macos) {
  os = `Mac`
}
if (platform.linux) {
  os = `Linux`
}
if (platform.windows) {
  os = `Windows`
}

const SectionWrapper = styled.div`
  padding: 32px 0;
  background-color: rgb(200, 200, 200);
  border-radius: 8px;
`
const DownloadWrapper = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
`
const ImageContainer = styled.div`
  text-align: center;
  max-width: 100%;
  padding: 32px;
`
const Spacer = styled.div`
  height: ${({ space = 64 }: { space?: number }) => `${space}px`};
`
const Home: React.FC<PageProps> = ({ path }) => {
  const [defaultLink, setDefaultLink] = React.useState(``)
  const [linuxLink, setLinuxLink] = React.useState(``)
  const [macLink, setMacLink] = React.useState(``)
  const [windowsLink, setWindowsLink] = React.useState(``)
  React.useEffect(() => {
    fetch(
      `https://api.github.com/repos/tengable/tengable-releases/releases/latest`,
    )
      .then((res) => res.json())
      .then((response) => {
        const assetUrls = (response?.assets || []).map(
          (asset) => asset.browser_download_url,
        )
        const linuxUrl = assetUrls.find((url) => url.indexOf(`AppImage`) !== -1)
        setLinuxLink(linuxUrl)
        const osxUrl = assetUrls.find((url) => url.indexOf(`dmg`) !== -1)
        setMacLink(osxUrl)
        const winUrl = assetUrls.find((url) => url.indexOf(`msi`) !== -1)
        setWindowsLink(winUrl)
        const urlMap = { Mac: osxUrl, Windows: winUrl, Linux: linuxUrl }
        if (urlMap[os]) setDefaultLink(urlMap[os])
      })
  }, [os])
  return (
    <PageLayout pathname={path}>
      <DownloadHeader os={os} defaultLink={defaultLink} />
      <Spacer />
      <Container>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <SectionWrapper>
              <ContentSlide
                slideLeft
                leftSizeXS={12}
                rightSizeXS={12}
                left={
                  <ImageContainer>
                    <StaticImage
                      placeholder="blurred"
                      src="../img/undraw/undraw_responsive_6c8s.svg"
                      width={971}
                      height={502}
                      alt="mac-laptop"
                    />
                  </ImageContainer>
                }
                right={
                  <DownloadWrapper>
                    <h2>Download for Mac</h2>
                    <Spacer space={32} />
                    <ButtonLink href={macLink}>Download DMG</ButtonLink>
                  </DownloadWrapper>
                }
              />
            </SectionWrapper>
          </Grid>
          <Grid item lg={6}>
            <SectionWrapper>
              <ContentSlide
                slideRight
                rightSizeXS={12}
                leftSizeXS={12}
                left={
                  <ImageContainer>
                    <StaticImage
                      placeholder="blurred"
                      src="../img/undraw/undraw_starry_window_ppm0.svg"
                      width={928}
                      height={743}
                      alt="mac-laptop"
                    />
                  </ImageContainer>
                }
                right={
                  <DownloadWrapper>
                    <h2>Download for Windows</h2>
                    <Spacer space={32} />
                    <ButtonLink href={windowsLink}>Download MSI</ButtonLink>
                  </DownloadWrapper>
                }
              />
            </SectionWrapper>
          </Grid>
          <Grid item lg={6}>
            <SectionWrapper>
              <ContentSlide
                slideLeft
                rightSizeXS={12}
                leftSizeXS={12}
                left={
                  <ImageContainer>
                    <StaticImage
                      placeholder="blurred"
                      src="../img/undraw/undraw_secure_server_s9u8.svg"
                      width={866}
                      height={720}
                      alt="mac-laptop"
                    />
                  </ImageContainer>
                }
                right={
                  <DownloadWrapper>
                    <h2>Download for Linux</h2>
                    <Spacer space={32} />
                    <ButtonLink href={linuxLink}>Download AppImage</ButtonLink>
                  </DownloadWrapper>
                }
              />
            </SectionWrapper>
          </Grid>
        </Grid>
      </Container>
    </PageLayout>
  )
}
export default Home
